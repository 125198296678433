import { debounce } from './functions';

class Scroll {
  constructor(isOnePager) {
    this.scrollDuration = 300;
    this.isOnePager = isOnePager;
    this.init();
  }

  init() {
    // function fires only when .lnk-section and minimum one scroll button with "[data-js-target="next-section-scroll]" exist.
    if (
      ($('[data-js-target="next-section-scroll"]') && $('.lnk-section'))
        .length >= 1
    ) {
      this.stageNextSectionScroll();
    }

    this.ankerLinks();
    this.scrollToTop();

    // Initialize bootstrap scrollspy for one pager
    if (this.isOnePager) {
      $('body').scrollspy({
        target: '#navScrollSpy',
        offset: $('.lnk-header').height() + 5,
      });
    }
  }

  /**
   * All anchor links with matching hash scrolls animated to destination
   */
  ankerLinks() {
    $('a[href^="#"]:not([data-href])').on('click', function (e) {
      if (e.target.hash !== '') {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - $('.lnk-header').height(),
            },
            this.scrollDuration,
            'swing',
          );
      }
    });
  }

  scrollToTop() {
    $('.lnk-scrollto').on('click', function () {
      $('html, body').stop().animate(
        {
          scrollTop: 0,
        },
        this.scrollDuration,
        'swing',
      );
    });

    $(window).on(
      'scroll',
      debounce(function () {
        if ($(window).scrollTop() > $(window).height()) {
          $('.lnk-scrollto').removeClass('d-none');
        } else {
          $('.lnk-scrollto').addClass('d-none');
        }
      }, 250),
    );
  }

  stageNextSectionScroll() {
    let target = $('.lnk-section')[0].id;
    $('[data-js-target=next-section-scroll]').attr('href', '#' + target);
  }
}

export default Scroll;
