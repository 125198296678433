import { getSystemState } from '../../../../basic-templating-kit/webresources-src/js/custom/functions';

class ExternalLinkChecker {
  constructor(confirmTexts = {}) {
    this.confirmTexts = confirmTexts;

    /**
     * TODO: Review by frontend team
     * Link checking only if this is not the author instance in edit mode
     */
    if (!getSystemState().isEditMode) {
      this.init();
    }
  }

  init() {
    if (Object.keys(this.confirmTexts).length === 0) {
      this.confirmTexts.confirmTitle = $('body').attr(
        'data-external-link-title',
      );
      this.confirmTexts.confirmButton = $('body').attr(
        'data-external-link-button',
      );
      this.confirmTexts.confirmText = $('body').attr('data-external-link-text');
      this.confirmTexts.cancelButton = $('body').attr(
        'data-external-link-cancel-button',
      );
    }
    this.enableEventListener();
  }

  enableEventListener() {
    const that = this;
    const originURL = location.origin;
    const links = $('a[href^="http"]:not([href="' + originURL + '"])');
    links.each(function () {
      const href = this.href;
      this.href = '#';
      $(this).attr('data-href', href);
      $(this).on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const href = event.currentTarget.dataset.href;
        that.generateModal(href, that.confirmTexts);
      });
    });
  }

  generateModal(href, confirmTexts) {
    const modal = `
    <div class="modal external-link-checker" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${confirmTexts.confirmTitle}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              ${confirmTexts.confirmText}
            </p>
            <p>
              ${href}
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">${confirmTexts.cancelButton}</button>
            <a href="${href}" target="_blank" class="btn btn-primary">${confirmTexts.confirmButton}</a>
          </div>
        </div>
      </div>
    </div>
    `;
    $('body').append(modal);
    $('.external-link-checker').modal();

    $('.external-link-checker').on('hidden.bs.modal', function () {
      $('.external-link-checker').modal('dispose');
      $('.external-link-checker').remove();
    });
  }
}

export default ExternalLinkChecker;
