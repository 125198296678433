import jobs from './custom/jobs';
import footer from './custom/footer';
import VWGISScroll from './custom/scroll';

$(document).ready(function () {
  if ($('.lnk-jobs').length > 0) {
    new jobs();
  }

  new footer();
  new VWGISScroll();
});
