import {
  debounce,
  returnViewport,
  getSystemState,
} from '../../../../basic-templating-kit/webresources-src/js/custom/functions';

class Footer {
  constructor() {
    if (!getSystemState().isEditMode) {
      this.init();
    }
  }

  init() {
    this.detectFooter();
    this.scrollEvent();
    this.windowsResizeEvent();
  }

  /**
   * triggers on scrolling
   */
  scrollEvent() {
    $(window).on(
      'scroll',
      debounce(() => {
        this.detectFooter();
      }, 50),
    );
  }

  /**
   * triggers on viewport resize
   */
  windowsResizeEvent() {
    $(window).on(
      'resize',
      debounce(() => {
        this.detectFooter();
      }, 300),
    );
  }

  /**
   * vars detect current scroll height and will check if footer displayed after scrolling
   */
  detectFooter() {
    let docViewTop = $(window).scrollTop();
    let docViewBottom = docViewTop + $(window).height();

    let elemTop = $('.lnk-footer').offset().top;

    let inView = elemTop + 50 <= docViewBottom;

    // Viewport xs specific
    if (returnViewport().currentViewport === 'xs') {
      if (!inView) {
        $('.lnk-footer .lnk-footer__link-list ul').css({
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        });
        $('.lnk-footer .lnk-footer__link-list ul li:not(:last-child)').css({
          paddingRight: '10px',
        });
      }
    } else {
      if (!inView) {
        $('.lnk-footer .lnk-footer__link-list ul').css({
          justifyContent: 'flex-end',
          display: 'flex',
        });
      }
    }

    // For all viewports
    if (inView) {
      $('.lnk-footer .lnk-footer__link-list').css({
        position: 'unset',
        backgroundColor: 'transparent',
        width: 'auto',
        bottom: 'auto',
        left: 'auto',
        transition: 'background-color 0.5s linear',
      });
      $('.lnk-footer .lnk-footer__link-list ul').removeClass('container');
      $('.lnk-footer .lnk-footer__link-list ul').css({
        justifyContent: 'flex-end',
        display: 'flex',
      });
      $('.lnk-footer .lnk-footer__link-list ul li:not(:last-child)').css({
        paddingRight: '0',
      });
    } else {
      $('.lnk-footer .lnk-footer__link-list').css({
        position: 'fixed',
        backgroundColor: '#4c5356',
        width: '100%',
        bottom: '0',
        left: '0',
        transition: 'background-color 0.5s linear',
        zIndex: '10',
      });
      $('.lnk-footer .lnk-footer__link-list ul').addClass('container');
    }
    this.scrollToTopPosition(inView, returnViewport().currentViewport);
  }

  /**
   * Because of the sticky footer we need dynamic scroll to top positioning
   * @param {*} footerInView - if footer in view
   * @param {*} currentViewport - returns current viewport
   */

  scrollToTopPosition(footerInView, currentViewport) {
    const smallViewports = ['md', 'sm', 'xs'];
    const smallViewportScrollToBottom = 4;
    const bigViewportScrollToBottom = 50;

    this.setScrollToTopPositioning(
      footerInView,
      smallViewports.some((vp) => vp === currentViewport)
        ? smallViewportScrollToBottom
        : bigViewportScrollToBottom,
    );
  }

  /**
   * Set the calculated bottom positioning of the scroll to top
   * @param {*} footerInView - if footer in view
   * @param {*} bottomPosition
   */
  setScrollToTopPositioning(footerInView, bottomPosition) {
    const marginToStickyFooter = 10;

    let bottomOffset = footerInView
      ? bottomPosition
      : $('.lnk-footer__link-list').height();

    bottomOffset += marginToStickyFooter;

    $('.lnk-scrollto').css({
      bottom: bottomOffset,
    });
  }
}

export default Footer;
