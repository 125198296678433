class HTML5Video {
  constructor(video) {
    this.video = video;
    this.playButton = $(video).parent().children('.vjs-big-play-button')[0];
    this.init();
  }

  init() {
    this.play();
    // this.stop();
  }

  play() {
    let video = this.video;
    let playButton = this.playButton;
    $(this.playButton).on('click', function () {
      video[0].play();
      $(playButton).hide();
    });
  }
  // stop() {
  //   let video = this.video;
  //   let playButton = this.playButton;
  //   $(video).parent().on('click', function() {
  //     video[0].pause();
  //     $(playButton).show();
  //     console.log('click')
  //   })
  // }
}

export default HTML5Video;
