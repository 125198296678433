import {
  debounce,
  returnViewport,
  getContextPath,
  getSystemState,
} from '../../../../basic-templating-kit/webresources-src/js/custom/functions';
import externallinkchecker from '../../../../basic-templating-kit/webresources-src/js/custom/externallinkchecker';

class Jobs {
  constructor() {
    this.confirmTexts = {};
    this.api = getContextPath() + '.rest/v1/jobs';
    this.jobs = null;
    this.filter = null;
    this.inputValue = '';
    this.icons = {
      municipality:
        '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M12.009 21.9285l-.42-.667c-.208-.331-5.089-8.149-5.089-13.487 0-3.092 2.519-5.703 5.5-5.703s5.5 2.592 5.5 5.66c0 5.541-4.86 13.209-5.067 13.533l-.424.664zM12 3.0715c-2.439 0-4.5 2.153-4.5 4.703 0 4.162 3.321 10.214 4.515 12.254 1.192-2.017 4.485-7.977 4.485-12.297 0-2.526-2.061-4.66-4.5-4.66zm.03 7.354l-.03-1c1.061-.011 1.906-.866 1.906-1.906 0-1.051-.855-1.906-1.906-1.906s-1.906.855-1.906 1.906h-1c0-1.603 1.304-2.906 2.906-2.906s2.906 1.304 2.906 2.906c0 1.585-1.29 2.889-2.876 2.906z" id="locationa"/></defs><g fill="none" fill-rule="evenodd"><mask id="locationb" fill="#fff"><use xlink:href="#locationa"/></mask><use fill-rule="nonzero" xlink:href="#locationa"/><g mask="url(#locationb)" class="icon-g"><path d="M0 0h24v24H0z"/></g></g></svg>',
      jobGrade:
        '<svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M14.999875 20.6253125c-.095 0-.19125-.0225-.28-.06625l-10-5 .56-1.1175 9.72 4.86 9.72-4.86.56 1.1175-10 5c-.08875.04375-.185.06625-.28.06625m-8.602375-10.625L15 14.3015625l8.6025-4.30125L15 5.6990625l-8.6025 4.30125zm18.322375 9.44125l.56 1.1175-10 5c-.08875.04375-.185.06625-.28.06625-.095 0-.19125-.0225-.28-.06625l-10-5 .56-1.1175 9.72 4.86 9.72-4.86zm-9.441125-15l10 5c.21125.10625.34625.3225.34625.55875 0 .23625-.135.4525-.34625.55875l-10 5c-.0875.04375-.18375.06625-.27875.06625s-.19125-.0225-.28-.06625l-10-5c-.21125-.10625-.345-.3225-.345-.55875 0-.23625.13375-.4525.345-.55875l10-5c.17625-.08875.38375-.08875.55875 0z" id="experiencea"/></defs><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><mask id="experienceb" fill="#fff"><use xlink:href="#experiencea"/></mask><use xlink:href="#experiencea"/><g mask="url(#experienceb)" class="icon-g"><path d="M0 0h30v30H0z"/></g></g></svg>',
      jobPlan:
        '<svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M6.25 23.75H17.5V12.5H6.25v11.25zM24.37525 5c.345 0 .625.28.625.625v12.5c0 .345-.28.625-.625.625H18.75v5.625c0 .345-.27875.625-.625.625h-12.5C5.28 25 5 24.72 5 24.375v-12.5c0-.345.28-.625.625-.625h12.5c.34625 0 .625.28.625.625V17.5h5.00025V6.25h-11.25v2.5h-1.25V5.625c0-.345.28-.625.625-.625h12.5z" id="activitya"/></defs><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><mask id="activityb" fill="#fff"><use xlink:href="#activitya"/></mask><use  xlink:href="#activitya"/><g mask="url(#activityb)" class="icon-g"><path d="M0 0h30v30H0z"/></g></g></svg>',
      closeFilter:
        '<svg viewBox="0 0 19 9" xmlns="http://www.w3.org/2000/svg"><defs><path d="M9 8.087L0 .775.63 0l8.685 7.054L18 0l.63.775-9 7.312a.496.496 0 01-.63 0z" id="a"/></defs><path d="M9 8.087L0 .775.63 0l8.685 7.054L18 0l.63.775-9 7.312a.496.496 0 01-.63 0z" fill-rule="evenodd"/></svg>',
      openFilter:
        '<svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg"><defs><path d="M11.25 10.109L0 .969.787 0l10.857 8.818L22.5 0l.788.969-11.25 9.14a.62.62 0 01-.788 0z" transform="matrix(1 0 0 -1 0 10.249)" id="a"/></defs><path d="M11.25.14L0 9.28l.787.969L11.644 1.43 22.5 10.25l.788-.969L12.038.14a.62.62 0 00-.788 0z" fill-rule="evenodd"/></svg>',
      linkIndicator:
        '<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><defs><path id="a" d="M37.84 41.42L36.44 40l9.12-9H12a1 1 0 110-2h33.56l-9.12-9 1.4-1.42 10.86 10.7a1.02 1.02 0 010 1.44l-10.86 10.7z"/></defs><path stroke="null" fill-rule="evenodd" fill="#none" d="M28.141 32.047l-1.456-1.476 9.485-9.36H1.267a1.04 1.04 0 110-2.08H36.17l-9.485-9.36 1.456-1.478 11.295 11.129a1.06 1.06 0 010 1.497L28.141 32.047z"/></svg>',
    };
    if (!getSystemState().isEditMode) {
      this.init();
    }
  }

  init() {
    this.getRequest();
    this.inputKeyUpEvent();

    // Mobile
    this.mobileHandling();
    this.mobileFilterOpenerEvent();
    this.mobileCloseFilter();
    this.confirmTexts.confirmTitle = $('.lnk-jobs').attr(
      'data-jobs-confirm-title',
    );
    this.confirmTexts.confirmButton = $('.lnk-jobs').attr(
      'data-jobs-confirm-button',
    );
    this.confirmTexts.confirmText = $('.lnk-jobs').attr(
      'data-jobs-confirm-text',
    );
    this.confirmTexts.cancelButton = $('.lnk-jobs').attr(
      'data-jobs-cancel-button',
    );
  }

  /**
   * TODO: Review by frontend team
   * Get the i18n text wich loaded in html markup in the given attribute
   * name (@see jobs.ftl).
   * @param {key} - the data attribute name
   */
  getI18n(attr) {
    let i18n = $('.lnk-jobs').attr(attr);
    /**
     * Return the i18n key if no value (text) was loaded in html markup
     * or empty string if the data attribute not found
     */
    return i18n !== undefined ? i18n : '';
  }

  getRequest() {
    function transformArray(filterName, filterResp) {
      return filterResp
        .find((filter) => filter.name === filterName)
        .values.map((el) => {
          return { name: el, active: false };
        });
    }

    $.get(this.api, (data) => {
      this.jobs = data.jobs;
      // only if the rest api return any job offers
      if (this.jobs.length > 0) {
        this.filter = {
          municipality: transformArray('municipality', data.filter),
          jobPlan: transformArray('jobplan', data.filter), // resp is lowercase but needs to become camel case because of jobs resp
          jobGrade: transformArray('jobgrade', data.filter), // resp is lowercase but needs to become camel case because of jobs resp
        };
        this.renderFilter();
        this.renderJobs();
        this.clickOnCheckbox();
        this.resetFilterEvent();
        this.accordionClickEvent();
      } else {
        // TODO: Review by frontend team
        // Call up the filter method here so that a message is displayed
        // to the user that no jobs were found
        this.renderJobs();
      }
    });
  }

  renderFilter() {
    const parentContainer = $('.lnk-jobs-filter');

    const labels = {
      municipality: this.getI18n('data-i18n-municipality'), // Standorte
      jobGrade: this.getI18n('data-i18n-jobgrade'), // Erfahrungen
      jobPlan: this.getI18n('data-i18n-jobplan'), // Tätigkeitsfelder
    };
    for (let key in this.filter) {
      $(`
        <div
          class="py-1 lnk-jobs-filter__element"
          data-toggle="collapse"
          data-target="#${key}"
          type="button"
        >
          <span class="lnk-jobs__filter-category-icon">
            ${this.icons[key]}
          </span>
          ${labels[key]}
          <span class="lnk-jobs__filter-accordion-icon">
            ${this.icons.closeFilter}
          </span>
        </div>
        <div id="${key}" class="collapse"></div>
      `).appendTo(parentContainer);
      const collapsableContainer = parentContainer.find(`#${key}`);
      this.filter[key].forEach((object, index) => {
        $(`
            <div class="collapse-element">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="checkbox-${
                  key + index
                }" data-event="filter" data-filter="${
          object.name
        }" data-filter-category="${key}" class="mr-1" type="checkbox">
                <label class="custom-control-label" for="checkbox-${
                  key + index
                }">${object.name}</label>
              </div>
            </div>
          `).appendTo(collapsableContainer);
      });
    }
    $('.lnk-jobs_filter .collapse').collapse('hide');
  }

  renderJobs() {
    const parentContainer = $('.lnk-jobs__list').html('');

    if (this.filteredJobs().length !== 0) {
      $('.lnk-jobs__list').removeClass('d-none');
      $('.lnk-jobs__no-search-results').addClass('d-none');

      this.filteredJobs().forEach((job) => {
        $(`
        <div class="lnk-jobs__job-box">
          <a href="${job.link}" target="_blank" style="text-decoration: none;">
            <div class="d-flex flex-column">
              <div class="w-100 d-flex justify-content-between">
                <h6 style="width: 90%" class="lnk-jobs__job-box-headline m-0">${job.title}</h6>
                <span style="width: 10%" class="lnk-jobs__job-box-link-indicator">${this.icons.linkIndicator}</span>
              </div>
              <hr class="w-100">
              <div class="d-flex flex-column flex-xl-row justify-content-between">
                <div>
                  <span class="lnk-jobs__job-box-icon">
                    ${this.icons.municipality}
                  </span>
                  <span class="lnk-jobs__job-box-text">${job.municipality}</span>
                </div>
                <div>
                  <span class="lnk-jobs__job-box-icon">
                    ${this.icons.jobGrade}
                  </span>
                  <span class="lnk-jobs__job-box-text">${job.jobGrade}</span>
                </div>
                <div>
                  <span class="lnk-jobs__job-box-icon">
                    ${this.icons.jobPlan}
                  </span>
                  <span class="lnk-jobs__job-box-text">${job.jobPlan}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
        `).appendTo(parentContainer);
      });
    } else {
      $('.lnk-jobs__list').addClass('d-none');
      $('.lnk-jobs__no-search-results').removeClass('d-none');
    }
    if ($('[data-job-list-title=""]')) {
      $('[data-job-list-title=""]').remove();
    }
    $(
      `<h5 class="lnk-jobs__list-title" data-job-list-title="">${this.getI18n(
        'data-i18n-result',
      )} (${this.filteredJobs().length})</h5>`,
    ).prependTo($('.lnk-jobs__list-header'));

    new externallinkchecker(this.confirmTexts);
  }

  /**
   * clickOnCheckbox triggers after clicking a filter
   */
  clickOnCheckbox() {
    $('.lnk-jobs input[data-event="filter"]').on('click', (event) => {
      const filterName = event.currentTarget.dataset.filter;
      const filterCategory = event.currentTarget.dataset.filterCategory;

      let filterObject = this.filter[filterCategory].find(
        (item) => item.name === filterName,
      );
      filterObject.active = !filterObject.active;

      this.renderJobs();
    });
  }

  /**
   * filteredJobs function does the basic filter behavior
   * triggered by: click on checkbox and typing in search bar
   */
  filteredJobs() {
    const filteredJobsArr = this.jobs.filter((job) => {
      let accepted = false;
      if (
        /**
         * All filtered categoryies empty – initial state
         */
        (this.filterBlockEmpty(this.filter.jobPlan) &&
          this.filterBlockEmpty(this.filter.municipality) &&
          this.filterBlockEmpty(this.filter.jobGrade)) ||
        /**
         * if one jobPlan clicked (alone) or
         * + one or more municipality and/or jobGrade
         */
        (!this.filterBlockEmpty(this.filter.jobPlan) &&
          this.filter.jobPlan.find(
            (filterItem) => job.jobPlan === filterItem.name,
          ).active &&
          (this.filterBlockEmpty(this.filter.municipality) ||
            (!this.filterBlockEmpty(this.filter.municipality) &&
              this.filter.municipality.find(
                (filterItem) => job.municipality === filterItem.name,
              ).active)) &&
          (this.filterBlockEmpty(this.filter.jobGrade) ||
            (!this.filterBlockEmpty(this.filter.jobGrade) &&
              this.filter.jobGrade.find(
                (filterItem) => job.jobGrade === filterItem.name,
              ).active))) ||
        /**
         * if one municipality clicked (alone) or
         * + one or more jobPlan and/or jobGrade
         */
        (!this.filterBlockEmpty(this.filter.municipality) &&
          this.filter.municipality.find(
            (filterItem) => job.municipality === filterItem.name,
          ).active &&
          (this.filterBlockEmpty(this.filter.jobPlan) ||
            (!this.filterBlockEmpty(this.filter.jobPlan) &&
              this.filter.jobPlan.find(
                (filterItem) => job.jobPlan === filterItem.name,
              ).active)) &&
          (this.filterBlockEmpty(this.filter.jobGrade) ||
            (!this.filterBlockEmpty(this.filter.jobGrade) &&
              this.filter.jobGrade.find(
                (filterItem) => job.jobGrade === filterItem.name,
              ).active))) ||
        /**
         * if one jobGrade clicked (alone) or
         * + one or more jobPlan and/or municipality
         */
        (!this.filterBlockEmpty(this.filter.jobGrade) &&
          this.filter.jobGrade.find(
            (filterItem) => job.jobGrade === filterItem.name,
          ).active &&
          (this.filterBlockEmpty(this.filter.jobPlan) ||
            (!this.filterBlockEmpty(this.filter.jobPlan) &&
              this.filter.jobPlan.find(
                (filterItem) => job.jobPlan === filterItem.name,
              ).active)) &&
          (this.filterBlockEmpty(this.filter.municipality) ||
            (!this.filterBlockEmpty(this.filter.municipality) &&
              this.filter.municipality.find(
                (filterItem) => job.municipality === filterItem.name,
              ).active)))
      ) {
        accepted = true;
      }
      return accepted;
    });
    return this.filterBySearch(filteredJobsArr);
  }

  /**
   * filterBlockEmpty check if one single item in a category is active
   * if no item active the return is true
   * @param {category} - filter category
   */
  filterBlockEmpty(category) {
    return !category.some((item) => item.active);
  }

  /**
   * after typing in search input field, filterBySearch will be triggered and search for search value
   * if search value available, filtered job/jobs will be displayed
   * @param {jobs} - all jobs or filtered jobs by filter
   */
  filterBySearch(jobs) {
    return jobs.filter((job) => {
      // title, municipality, jobGrade, jobPlan
      return (
        job.title.toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1 ||
        job.municipality
          .toLowerCase()
          .indexOf(this.inputValue.toLowerCase()) !== -1 ||
        job.jobGrade.toLowerCase().indexOf(this.inputValue.toLowerCase()) !==
          -1 ||
        job.jobPlan.toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1
      );
    });
  }

  /**
   * Event for every single keyup in input field (search)
   */
  inputKeyUpEvent() {
    $('.lnk-jobs__search input').on('keyup', (event) => {
      this.inputValue = event.target.value;
      this.renderJobs();
    });
  }

  /**
   * reset filter button will remove all marked filters (mobile/desktop)
   */
  resetFilterEvent() {
    $('.lnk-jobs [data-reset="true"]').on('click', () => {
      for (let key in this.filter) {
        this.filter[key].forEach((object) => {
          object.active = false;
        });
      }
      $('.lnk-jobs input[data-event="filter"]').prop('checked', false);
      $('.lnk-jobs__search input').val('');
      this.inputValue = '';
      this.renderJobs();
    });
  }

  /**
   * click on filter opener in small viewport will open the filter layer (not a lightbox)
   */
  mobileFilterOpenerEvent() {
    $('.lnk-jobs__filter-opener').on('click', () => {
      $('.lnk-jobs__filter').addClass('lnk-jobs__filter--mobile');
      $('body').addClass('overflow-hidden');
    });
  }

  /**
   * click on the mobile Button will remove modal-open and lnk-jobs__filter--mobile class
   */
  mobileCloseFilter() {
    $('.lnk-jobs [data-filter="close"]').on('click', () => {
      $('.lnk-jobs__filter').removeClass('lnk-jobs__filter--mobile');
      $('body').removeClass('overflow-hidden');
    });
  }

  /**
   * acordion open/close icon handling
   */
  accordionClickEvent() {
    $('.lnk-jobs-filter__element').on('click', (event) => {
      // You have to intercept the double click in order not to let errors arise
      if ($(event.target).siblings('.collapsing').length === 0) {
        const iconOpenClass = 'lnk-jobs__filter-accordion-icon--open';
        const targetIcon = $(event.target).children(
          '.lnk-jobs__filter-accordion-icon',
        );

        targetIcon.hasClass(iconOpenClass)
          ? targetIcon.removeClass(iconOpenClass)
          : targetIcon.addClass(iconOpenClass);
      }
    });
  }

  /**
   * if mobile filter area open und you resize the viewport to lg/xl the filter area will be closed
   */
  mobileHandling() {
    $(window).on(
      'resize',
      debounce(function () {
        const currentViewport = returnViewport().currentViewport;
        if (currentViewport === 'lg' || currentViewport === 'xl') {
          $('.lnk-jobs__filter').removeClass('lnk-jobs__filter--mobile');
        }
      }, 300),
    );
  }
}

export default Jobs;
