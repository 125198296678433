class Cookie {
  constructor() {
    // if tracking is enabled, the html tag with ID lnk-cookie exists
    if ($('#lnk-cookie').length > 0) {
      this.init();

      // check in the querystring if we should open the cookiemanager (?cookiemanager)
      this.queryobject = this.queryStringParse(window.location.search);
      // it's defined, but null when it has no value to the key (?cookiemanager)
      if (this.queryobject.cookiemanager === null) {
        this.openCookieManager();
      }
      // show the banner if no chioce has been made yet
      if (localStorage.getItem('selectedCookie') === null) {
        // fresh start
        this.activateCookieBanner();
      }
    }
  }

  // init: bring event-handlers to the buttons
  init() {
    $('[data-accept-cookie]').on('click', () => this.accept());
    $('[data-decline-cookie]').on('click', () => this.decline());
    $('[data-config-cookie]').on('click', () => this.openCookieManager());
    $('[data-config-cookie-cancel]').on('click', () => this.cancel());
    $('[data-config-cookie-cancel-manager]').on('click', () =>
      this.cancelManager(),
    );
    $('[data-config-cookie-savechoice]').on('click', () =>
      this.saveuserchoice(),
    );

    // when user clicks the 'x'-icon on the right-top of the modal it closes and should reopen the cookie-banner
    $('#lnk-cookiemanager').on('hidden.bs.modal', function () {
      $('.lnk-cookie').removeClass('fade d-none');
    });
  }
  // we inject the stats-js directly into the head
  // user can continue browsing without a page-reload
  injectToHead() {
    // only if it is not a author-instance (only on public-instance)
    if ($('.lnk-cookie--isauthor').length <= 0) {
      this.injectStatstracking();
    }
  }

  // tracking (matamo)
  injectStatstracking() {
    // remove and inject again, to start tracking, without page-reload
    const trackingscript = document.getElementById('lnk-tracking-stats');

    // get the absolute-path of the magnolia-webroot to know the correct path for the inject
    const webroot = document.getElementById('lnk-cookie');
    let webrootpath = webroot.dataset.contextpath;

    // add a trailing-slash, if needed (non-root-installs)
    if (webrootpath !== '/') webrootpath += '/';

    // avoid possible js-errors on author
    if (trackingscript !== null) {
      trackingscript.remove();
    }

    // inject
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'lnk-tracking-stats';
    script.src =
      webrootpath +
      '.resources/client-module/client-theme/js/tracking/tracking.js';
    document.head.appendChild(script);
  }
  // make the banner visible
  activateCookieBanner() {
    $('.lnk-cookie').addClass('lnk-cookie--active');
  }
  // make default-checkboxstate correctly based on localstorage
  preselectCheckboxes() {
    if (localStorage.getItem('cookie-config-stats') === 'true') {
      document.getElementById('lnk-cookie-config-stats').checked = true;
    }
  }

  // TODO: extend this, when more options are available
  // currently only checks: "stats" checkbox
  saveuserchoice() {
    var userchoice_stats = $('#lnk-cookie-config-stats').prop('checked');

    if (userchoice_stats) {
      this.accept();
    } else {
      this.decline();
    }
  }

  // refactor and combine to single function, when more options are added
  accept() {
    localStorage.setItem('cookie-config-stats', true);
    localStorage.setItem('selectedCookie', true);
    this.injectToHead();
    this.remove();
    $('#lnk-cookiemanager').modal('hide');
  }

  decline() {
    localStorage.setItem('cookie-config-stats', false);
    localStorage.setItem('selectedCookie', true);
    this.remove();
    $('#lnk-cookiemanager').modal('hide');
  }

  // cancel the banner
  cancel() {
    this.remove();
  }
  // make banner invisible and open cookie-manager
  openCookieManager() {
    $('.lnk-cookie').addClass('fade');
    this.preselectCheckboxes();
    // $('.lnk-cookie').addClass('lnk-cookie--active');
    $('#lnk-cookiemanager').modal('show');
  }

  // cancel the cookie-manager
  cancelManager() {
    $('.lnk-cookie').removeClass('fade d-none');
  }
  // remove the cookie-banner
  remove() {
    $('[data-accept-cookie], [data-decline-cookie]').off();
    $('.lnk-cookie').remove();
  }

  hasCookiesAccepted() {
    if (
      localStorage.getItem('selectedCookie') === null ||
      localStorage.getItem('selectedCookie') === 'false'
    ) {
      return false;
    } else {
      return true;
    }
  }
  // Deserialize query string to object
  queryStringParse(str) {
    var ret = Object.create(null);

    if (typeof str !== 'string') {
      return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
      return ret;
    }

    str.split('&').forEach(function (param) {
      var parts = param.replace(/\+/g, ' ').split('=');
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      var key = parts.shift();
      var val = parts.length > 0 ? parts.join('=') : undefined;

      key = decodeURIComponent(key);

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? null : decodeURIComponent(val);

      if (ret[key] === undefined) {
        ret[key] = val;
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val);
      } else {
        ret[key] = [ret[key], val];
      }
    });

    return ret;
  }
}

export default Cookie;
