import { debounce } from './functions';

class Search {
  constructor() {
    this.heightOfHeader = 'null';
    this.searchForm = $('#lnksearch');
    this.footer = this.searchForm.find('.modal-footer');
    this.footerResults = this.footer.find('.lnk-search__results');
    this.footerNoResults = this.footer.find('.lnk-search__no-results');
    this.searchText = '';
    this.csrf = this.searchForm.find('input[name="csrf"]')[0].value;
    this.actionURL = this.searchForm.find('form').attr('action');
    this.init();
  }

  init() {
    this.openSearch();
    this.closeSearch();
    this.submitSearch();
    this.onResize();
    this.onCloseByIconToggle();
  }

  /**
   * handling for open search modal
   * - calculate max-height of modal by getting height of navbar
   * - set results-containers to display none
   * - added styles for navbar (should be visible)
   */
  openSearch() {
    $('#lnksearch').on('shown.bs.modal', (e) => {
      let offsetTop = this.calcOffsetTopForModalFooter($(e.delegateTarget));
      $(e.delegateTarget)
        .find('.modal-footer')
        .css('max-height', offsetTop + 'px');
      $(e.delegateTarget).find('input[type="text"]')[0].focus();
    });

    $('#lnksearch').on('show.bs.modal', (e) => {
      this.heightOfHeader = 0;

      this.footer.addClass('invisible');
      this.footerResults.addClass('d-none');
      this.footerNoResults.addClass('d-none');

      $('.lnk-header__search').addClass('is-header-icon--active');
      $('.lnk-header__search').attr('data-close-search', '');
      $('.is-header-icon:not(.lnk-header__search)').addClass(
        'is-header-icon--inactive',
      );

      $(e.delegateTarget).css('display', 'none');
      $(e.delegateTarget)
        .find('.modal-dialog')
        .css('margin-top', this.heightOfHeader + 'px');
      // $('.lnk-header').css({
      //   'background-color': 'inherit',
      //   'position': 'relative',
      //   'z-index': 1600
      // });
    });
  }

  /**
   * handling for closing search modal
   * - remove navbar styles and margin-top offset of search modal
   */
  closeSearch() {
    $('#lnksearch').on('hidden.bs.modal', (e) => {
      $(e.delegateTarget).find('.modal-dialog').css('margin-top', '');
      // $('.lnk-header').removeAttr('style');
      $('.is-header-icon')
        .removeClass('is-header-icon--inactive')
        .removeClass('is-header-icon--active');
      $('.lnk-header__search').removeAttr('data-close-search');
    });
  }

  /**
   * handling for closing search modal by click on the icon which opens the search
   */
  onCloseByIconToggle() {
    $('.lnk-header__search').on('click', (e) => {
      if ($(e.currentTarget).hasClass('is-header-icon--active')) {
        this.searchForm.modal('hide');
      }
    });
  }

  /**
   * handling for send search request
   * - show results or no-results-found - container depending on response
   */
  submitSearch() {
    $('#lnksearch button[type="submit"]').on('click', (e) => {
      e.preventDefault();
      this.footer.addClass('invisible');
      this.footerResults.addClass('d-none');
      this.footerNoResults.addClass('d-none');
      this.searchText = this.searchForm.find('input[name="queryStr"]')[0].value;
      this.footerResults.load(
        this.actionURL + ' li',
        { queryStr: this.searchText, csrf: this.csrf },
        (data) => {
          if (data.trim()) {
            this.footerResults.removeClass('d-none');
          } else {
            this.footerNoResults.removeClass('d-none');
          }
          this.footer.removeClass('invisible');
        },
      );
    });
  }

  /**
   * handling resize changes of browser window
   * - added debouncer for preventing to much resize changes
   */
  onResize() {
    $(window).on(
      'resize',
      debounce(() => {
        if ($('body.modal-open #lnksearch').hasClass('show')) {
          let offsetTop = this.calcOffsetTopForModalFooter($('#lnksearch'));
          $('#lnksearch')
            .find('.modal-footer')
            .css('max-height', offsetTop + 'px');
        }
      }, 250),
    );
  }

  /**
   * Returns the max-height of the search modal
   * @param  {DOM - Element} target: the search modal
   * @return {Integer}        the calculated max-height
   */
  calcOffsetTopForModalFooter(target) {
    // return $(window).height() - $(target).find('.modal-footer').offset().top - 40
    return (
      $(window).height() - $(target).find('.modal-footer').offset().top - 30
    );
  }
}

export default Search;
