/**
 *
 * @param {
 *   delay?: number, // optional (default 5000ms; 0 = no autohide)
 *   type: 'error' | 'success' | 'warning',
 *   msg: string
 * } data
 */
export function generateNotification(data) {
  checkNotificationWrapper();
  const el = $(notificationTemplate(data));
  $('.lnk-notification-wrapper').append(el);

  const opt = setNotificationOptions(data);

  el.toast(opt).toast('show');

  onNotificationHidden(el);
}

function checkNotificationWrapper() {
  const notificationWrapper = $('body').find('.lnk-notification-wrapper');
  if (!notificationWrapper.length) {
    $('body').append(
      '<div class="lnk-notification-wrapper" style="right: 0; bottom: 0; position: fixed;"></div>',
    );
  }
}

/**
 *
 * @param {*} data - see method generateNotification
 */
function notificationTemplate(data) {
  return `
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">${data.type}</strong>
    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="toast-body">
    ${data.msg}
  </div>
</div>`;
}

/**
 *
 * @param {*} data - see method generateNotification
 */
function setNotificationOptions(data) {
  const opt = {};
  if (data.delay === undefined) {
    opt.delay = 5000; // default
  }

  if (data.delay !== undefined && data.delay !== 0) {
    opt.delay = data.delay;
  }

  if (data.delay !== undefined && data.delay === 0) {
    opt.autohide = false;
  }

  return opt;
}

function onNotificationHidden(el) {
  el.on('hidden.bs.toast', function () {
    el.remove();
    if ($('body').find('.lnk-notification-wrapper .toast').length === 0) {
      $('.lnk-notification-wrapper').remove();
    }
  });
}
