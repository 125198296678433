class Mediacarousel {
  constructor(mainCarousel) {
    this.init(mainCarousel);
  }

  init(mainCarousel) {
    this.initMediaCarousel(mainCarousel);
    this.videoPausedAfterSlide();
    this.handleVideoFullScreenIssue(mainCarousel);
  }

  initMediaCarousel(mainCarousel) {
    let carouselID = mainCarousel.find('.slider-for').attr('id');
    let data_xl = mainCarousel.attr('data-xl');
    let data_lg = mainCarousel.attr('data-lg');
    let data_xs = mainCarousel.attr('data-xs');

    mainCarousel.find('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      adaptiveHeight: true,
    });

    mainCarousel.find('.slider-nav').slick({
      slidesToShow: data_xl,
      slidesToScroll: 1,
      asNavFor: '#' + carouselID,
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      adaptiveHeight: true,
      infinite: true,
      prevArrow: mainCarousel.find('.previous'),
      nextArrow: mainCarousel.find('.next'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: data_lg,
            centerMode: false,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: data_xs,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }

  /**
   * all videos will be on pause after slide the slick-carousel
   */
  videoPausedAfterSlide() {
    $('.slider-nav').on(
      'beforeChange',
      // eslint-disable-next-line no-unused-vars
      function (event, slick, currentSlide, nextSlide) {
        $('body')
          .find('video')
          .each(function (index, element) {
            element.pause();
          });
      },
    );
  }

  /**
   * There is a bug when having video inside the carousel and enter+leave fullscreen mode.
   * The carousel set its height to 1px.
   * This workaround saves the height when enter fullscreen and sets this saved height when leaving the fullscreen mode.
   * @param mainCarousel
   */
  handleVideoFullScreenIssue(mainCarousel) {
    mainCarousel.find('[data-is-vjs]').each(function () {
      let savedHeight = null;
      const closestSlickList = $(this).closest('.slick-list.draggable');
      $(this)
        .parent()
        .on('fullscreenchange', function () {
          if (savedHeight) {
            // leave fullscreen
            closestSlickList.height(savedHeight);
            savedHeight = null;
          } else {
            // entering fullscreen
            savedHeight = closestSlickList.height();
          }
        });
    });
  }
}

export default Mediacarousel;
