import animation from './custom/animation';
import carousel from './custom/carousel';
import cookie from './custom/cookie';
import externallinkchecker from './custom/externallinkchecker';
import facts from './custom/facts';
import html5video from './custom/html5video';
import lightbox from './custom/lightbox';
// import lnkDebugger from './custom/debugger';
import navigation from './custom/navigation';
import search from './custom/search';
import scroll from './custom/scroll';
import table from './custom/table';
import mediacarousel from './custom/mediacarousel';
import badges from './custom/badges';
import hotspot from './custom/hotspot';

/**
 * Inits
 */

$(document).ready(function () {
  // Base Variables
  const isOnePager =
    typeof $('body').attr('data-one-pager') !== typeof undefined &&
    $('body').attr('data-one-pager') !== false;

  // Base Initializations
  $('table').each(function () {
    new table($(this));
  });

  $('.lnk-carousel').each(function () {
    new carousel($(this));
  });

  $('.lnk-facts').each(function () {
    new facts($(this));
  });

  new cookie();

  new lightbox();
  new search();

  new externallinkchecker();

  // new lnkDebugger();

  if ($(window).scrollTop() > $(window).height()) {
    $('.lnk-scrollto').removeClass('d-none');
  }

  if (!$('html').hasClass('no-js-editmode')) {
    new animation();
  }

  new scroll(isOnePager);

  new navigation(isOnePager);

  $('video:not([data-is-vjs])').each(function () {
    new html5video($(this));
  });

  $('.lnk-media-carousel').each(function () {
    new mediacarousel($(this));
  });

  $('.lnk-badges').each(function () {
    new badges($(this));
  });

  $('.lnk-hotspot').each(function () {
    new hotspot($(this));
  });
});
