class Navigation {
  constructor(isOnePager) {
    this.handleMobileMenuOpener();
    this.handleMobileSubmenu();
    if (isOnePager) {
      this.handleMobileMenuInOnePager();
    }
  }

  /**
   * Handler for opening the mobile navbar with button in header
   */
  handleMobileMenuOpener() {
    let headerNavbarToggler = document.querySelector(
      '.lnk-header-navbar-toggler',
    );
    headerNavbarToggler.addEventListener('click', () => {
      document
        .querySelector('.lnk-top-navigation')
        .classList.toggle('lnk-top-navigation--open');
      document
        .querySelector('.lnk-header__mobile-opener')
        .classList.toggle('is-header-icon--active');
      $('.is-header-icon:not(.lnk-header__mobile-opener)').toggleClass(
        'is-header-icon--inactive',
      );

      document.querySelector('body').classList.toggle('overflow-hidden');
    });
  }

  /**
   * In mobile menu handler for opening/closing submenu
   */
  handleMobileSubmenu() {
    let navsWithDropdown = document.querySelectorAll(
      'nav.navbar li.lnk-dropdown a.lnk-dropdown__arrow-for-small',
    );
    for (let item of navsWithDropdown) {
      item.addEventListener('click', (e) => {
        e.target.classList.toggle('active');
        e.target.parentNode.parentNode.children[1].classList.toggle('show');
      });
    }
  }

  /**
   * Handle mobile menu in One-Pager
   * -> close navigation when clicking on a menu item
   */
  handleMobileMenuInOnePager() {
    $('.nav-link').on('click', () => {
      let isMobileMenuOpen = $('.lnk-top-navigation').hasClass(
        'lnk-top-navigation--open',
      );
      if (isMobileMenuOpen) {
        $('.navbar-toggler').trigger('click');
      }
    });
  }
}

export default Navigation;
