import { debounce } from '../../../../basic-templating-kit/webresources-src/js/custom/functions';

class VWGISScroll {
  constructor() {
    this.sections = [];
    this.headerHeight = 0;
    this.init();
  }

  init() {
    this.getHeaderHeight();
    this.getSections();
    this.setScrollEventListener();
    this.setResizeEventListener();
    this.handlePageLoad();
  }

  handlePageLoad() {
    if (location.hash !== '') {
      let hash = location.hash;
      hash = hash.substr(1);
      const viewingSection = this.sections.find((el) => el.id === hash);
      window.scrollTo({
        top: viewingSection.offsetTop - this.headerHeight,
        behavior: 'auto',
      });
    }
  }

  /**
   * iterating all sections and get id and its offset to document top
   */
  getSections() {
    const that = this;
    $('section').each(function () {
      const section = {
        offsetTop: $(this).offset().top,
        id: $(this).attr('id'),
      };
      that.sections.push(section);
    });
    that.sections.sort((a, b) => (a.offsetTop > b.offsetTop ? -1 : 1));
  }

  /**
   * get the height of the main header
   */
  getHeaderHeight() {
    this.headerHeight = $('.lnk-header').outerHeight();
  }

  /**
   * checks if the current (window) view matches a section
   * add, edit or remove the location hash
   */
  checkCurrentView() {
    const section = this.sections.find(
      (el) => $(window).scrollTop() + this.headerHeight >= el.offsetTop,
    );
    // if the window view is inside or outside a section
    if (section) {
      // prevent setting url if previously url is the same (section to section jumping)
      if (location.hash !== '#' + section.id) {
        history.pushState('', '', '#' + section.id);
      }
    } else {
      // if view is outside a section and previously was on a section change the url.
      // if outside a section and previously also than nothing should be done
      if (location.hash !== '') {
        history.pushState(
          '',
          '',
          window.location.origin + window.location.pathname,
        );
      }
    }
  }

  /**
   * eventlistener for scroll
   */
  setScrollEventListener() {
    $(window).on(
      'scroll',
      debounce(() => {
        this.checkCurrentView();
      }, 250),
    );
  }

  /**
   * eventlistener for resize
   */
  setResizeEventListener() {
    $(window).on(
      'resize',
      debounce(() => {
        this.getHeaderHeight();
        this.getSections();
        this.checkCurrentView();
      }, 250),
    );
  }
}

export default VWGISScroll;
