import facts from './facts';
import mediacarousel from './mediacarousel';
import carousel from './carousel';
import { getURLParam } from './functions';
import badges from './badges';
import LoadingSpinner from '../../../../client-module/webresources-src/js/custom/loading-spinner';

class Lightbox {
  constructor() {
    this.savedLocationHash = '';
    this.id;
    this.init();
  }

  init() {
    this.prepareAjaxLinks();
    this.handleLightboxOpeningWithLinks();
    this.base64EncodeLightboxLink();
    this.linkHandlingOnURLOpening();
    this.copyLinkEvent();
    this.ajaxLightboxCloseListener();

    $('.modal[id^="video"] ').on('shown.bs.modal', function () {
      this.savedLocationHash = location.hash;
      let encodedVideo = $(this)
        .find('[data-encoded-video-url]')
        .attr('data-encoded-video-url');
      history.pushState('', '', '?video=' + encodedVideo);
    });
  }

  prepareAjaxLinks() {
    $('a[target="lightbox"]').each(function () {
      let url = $(this).attr('href');
      if (!url.match(/~main~/g)) {
        url = url.replace('.html', '~main~.html');
        $(this).attr('href', url);
      }

      $(this).removeAttr('data-target');
      $(this).removeAttr('data-toggle');
      $(this).attr('data-ajax-loaded', 'false');
    });
  }

  handleLightboxOpeningWithLinks() {
    const that = this;
    $('a[target="lightbox"]').on('click', function (e) {
      e.preventDefault();

      $('body')
        .find('video')
        .each(function (index, element) {
          element.pause();
        });

      const anchor = e.currentTarget;
      const isAjaxLoaded =
        $(anchor).attr('data-ajax-loaded') === 'true' ? true : false;
      const ajaxModalPreset = $('.modal[id="ajax-template"]');
      const url = $(anchor).attr('href');

      const hashedURL = btoa(url);
      const lightboxID = 'ID-' + hashedURL;
      const carouselID = 'ID-carousel-' + hashedURL;
      history.pushState('', '', '?url=' + hashedURL);

      // we need the lightbox id in the class for ajaxLightboxCloseListener (media carousel unslick)
      this.id = lightboxID;
      // link is not initialized with ajax request
      if (!isAjaxLoaded) {
        const clonedModal = ajaxModalPreset.clone();
        clonedModal.attr('id', lightboxID);
        clonedModal.find('.carousel').attr('id', carouselID);

        clonedModal
          .find('.carousel-inner')
          .append(new LoadingSpinner('mx-auto my-5').template());
        clonedModal.appendTo('body');

        // enable modal
        $('.modal[id="' + lightboxID + '"]').modal('show');

        // perform ajax request
        $.ajax({
          url: url,
          success: function (htmlData) {
            const modalContentContainer = $(
              '.modal[id="' + lightboxID + '"] .carousel-inner',
            );

            // filter only <section> - containers from response and append to cloned modal
            $('<div />')
              .append(htmlData)
              .find('section')
              .appendTo(modalContentContainer);

            // remove possible lightbox behaviour from new content to prevent opening another modal while lightbox is open
            modalContentContainer
              .find('[data-toggle="modal"]')
              .each(function () {
                $(this).removeAttr('data-toggle').removeAttr('data-target');
                $(this).on('click', function (eventInnerLightbox) {
                  eventInnerLightbox.preventDefault();
                });
              });

            // remove possible new lightboxes from new content
            modalContentContainer.find('.lnk-lightbox').each(function () {
              $(this).remove();
            });

            // initialize possible new facts component
            // have to wait for DOM rendered
            modalContentContainer.find('.lnk-facts').each(function () {
              let interval = setInterval(() => {
                if ($(this).height() > 0) {
                  clearInterval(interval);
                  new facts($(this));
                }
              }, 50);
            });

            // initialize possible new carousel component
            modalContentContainer.find('.lnk-carousel').each(function () {
              $('.lnk-carousel').each(function () {
                new carousel($(this));
              });
            });

            // initialize possible new badges component
            // have to wait for DOM rendered
            modalContentContainer.find('.lnk-badges').each(function () {
              let interval = setInterval(() => {
                if ($(this).height() > 0) {
                  clearInterval(interval);
                  new badges($(this));
                }
              }, 250);
            });

            // initialize possible new media carousel component
            // have to wait for DOM rendered
            that.mediaCarouselHandling(modalContentContainer);

            // remove loading spinner
            modalContentContainer.find('.lnk-loading-spinner').remove();

            // have to disable modal close event listener
            // reinitialzing them would fire event multiple times
            $('.lnk-lightbox--ajax-link, .modal[id^="video"]').off(
              'hidden.bs.modal',
            );
            // reinitialize modal close event
            // because dynamically created modals would not be recognized
            that.ajaxLightboxCloseListener();

            //  set information to link for ajax has been loaded
            $('[target="lightbox"][href="' + url + '"').attr(
              'data-ajax-loaded',
              'true',
            );
            // $(anchor).attr('data-ajax-loaded', 'true');
          },
          dataType: 'html',
        });
      } else {
        // if modal is already initalized (previously request)
        $('.modal[id="' + lightboxID + '"]').modal('show');

        const modalContentContainer = $(
          '.modal[id="' + lightboxID + '"] .carousel-inner',
        );
        that.mediaCarouselHandling(modalContentContainer);
      }
    });
  }

  mediaCarouselHandling(modalContentContainer) {
    modalContentContainer.find('.lnk-media-carousel').each(function () {
      let interval = setInterval(() => {
        if ($(this).height() > 0) {
          clearInterval(interval);
          new mediacarousel($(this));
          let t = 0;
          let intervalTime = 150;
          let carouselInitInterval = setInterval(() => {
            let sliderForWidth = $(this)
              .find('.slider-for .slick-list')
              .width();
            let firstSlideWidth = $(this).find('.slick-active')[0].clientWidth;
            // On mobile viewport the slick-list, slick-track and video have no width
            if (sliderForWidth === 0 && firstSlideWidth === 0) {
              $(this).find('.slider-for .slick-list').width('100%');
              $(this).find('.slider-for .slick-list').height('auto');
              $(this)
                .find('.slider-for .slick-list .slick-track:first-child')
                .width('100%');
              $(this).find('.slick-active:first-child').width('100%');
              clearInterval(carouselInitInterval);
            }
            if (sliderForWidth !== firstSlideWidth) {
              // trigger resize in window if different sizes of slick elements container and slick elements
              // especially for firefox problems while rendering
              clearInterval(carouselInitInterval);
            }

            if (t * intervalTime > 30000) {
              // timeout after 30 seconds
              clearInterval(carouselInitInterval);
            }
            t++;
          }, intervalTime);

          modalContentContainer.find('.lnk-video').each(function () {
            let id = $(this).attr('id');
            // eslint-disable-next-line no-undef
            videojs(id, {}, function () {});
          });
        }
      }, 250);
      $(window).trigger('resize');
    });
  }

  /**
   * Lightbox open by get param
   */
  linkHandlingOnURLOpening() {
    /**
     * FOR VIDEOS
     */
    $('.lnk-lightbox .lnk-video [data-linkcopy]').each(function () {
      if (
        $(this).attr('data-linkcopy') ==
        location.href.replace(location.search, '') +
          '?video=' +
          getURLParam('video')
      ) {
        $(this).parents('.modal').modal('show');
        return false;
      }
    });
    /**
     * FOR AJAX RQUESTS
     */

    if (
      getURLParam('url') &&
      atob(getURLParam('url')).indexOf('~main~') !== -1
    ) {
      setTimeout(function () {
        $(
          'a[target="lightbox"][href="' +
            // atob(getURLParam('url')).replace('~main~.html', '.html') +
            atob(getURLParam('url')) +
            '"]',
        )[0].click();
      }, 1000);
    }
  }

  /**
   * Change URL to a base64 string
   */
  base64EncodeLightboxLink() {
    $('.lnk-lightbox .lnk-video [data-linkcopy]').each(function () {
      let tempVideoURL = $(this).attr('data-linkcopy');
      let encodeVideoURL = btoa(tempVideoURL);
      $(this).attr(
        'data-linkcopy',
        location.href.replace(location.search, '') + '?video=' + encodeVideoURL,
      );
      $(this).attr('data-encoded-video-url', encodeVideoURL);
    });
  }

  /**
   * Link-URL copy with icon in the lightbox
   */

  copyLinkEvent() {
    $('.lnk-lightbox .lnk-video [data-linkcopy]').on('click', function () {
      let linkForCopyProgress = $(this).attr('data-linkcopy');
      $(this).append(
        '<textarea class="copy-field">' + linkForCopyProgress + '</textarea>',
      );
      $('.copy-field')[0].select();
      document.execCommand('copy');
      $('.copy-field').remove();
    });
  }

  ajaxLightboxCloseListener() {
    $('.lnk-lightbox--ajax-link, .modal[id^="video"]').on(
      'hidden.bs.modal',
      function () {
        $('.modal')
          .find('video')
          .each(function (index, element) {
            element.pause();
          });
        history.pushState(
          '',
          '',
          window.location.origin +
            window.location.pathname +
            (this.savedLocationHash ? this.savedLocationHash : ''),
        );

        const modalContentContainer = $(
          '.modal[id="' + this.id + '"] .carousel-inner',
        );
        modalContentContainer
          .find('.lnk-media-carousel .slick-slider')
          .each(function () {
            $(this).slick('unslick');
          });
      },
    );
  }
}

export default Lightbox;
